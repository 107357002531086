@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,200;0,400;0,700;1,200;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&display=swap');

* {
    padding: 0;
    margin: 0;
    font-family: 'Open Sans', sans-serif;
}

.header {
    background-color: #1B1B1B;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 20px;
    overflow: hidden;
}

.logo p {
    font-family: 'Macondo', cursive;
    color: #00b7ff;
    font-size: 2.5em;
}

nav {
    display: flex;
    flex-direction: row;
    gap: 30px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    list-style: none;
    color: white;
    font-size: 1.2em;
    cursor: pointer;
}

nav a {
    color: white;
    text-decoration: none;
}

nav a:hover {
    color:#00b7ff;
}

.mobile {
    display: none;
    color: white;
    cursor: pointer;
}

@media (max-width: 667px) {
    .mobile{
        display: block;
    }

    nav {
        display: none;
    }
}

@media(max-width: 992px) {

    .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 30px;
    }

}

