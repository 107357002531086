.projetos {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Open Sans', sans-serif;
    padding: 60px;
    background-color: #292929;
    color: white;
}

.projetos h2 {
    padding-bottom: 60px;
}

.projetos-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 40px;
}

.projetos .btn {
    display: flex;
    flex-direction: row;
    gap: 30px;
    text-align: center ;
}

.projetos-container div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.projetos-container div img {
    width: 400px;
    height: 200px;
    border-left: 5px solid #00b7ff;
    border-radius: 2px;
}

@media (max-width: 860px) {

    .projetos-container {
        display: grid;
        grid-template-columns: 1fr ;
        gap: 40px;
    }
}

@media (max-width: 418px) {
    .projetos-container div img {
        width: 200px;
        height: 100px;
    }

    .projetos .btn {
        display: flex;
        flex-direction: row;
        gap: 10px;
        text-align: center ;
    }
}