@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.inicio {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    background-color: #111111;
    color: white;
    padding: 100px 0;
}

.titulo {
    display: flex;
    flex-direction: column;
    text-align: center;
    font-size: 1.2em;
}

.titulo h1 {
    font-family: "Kanit", sans-serif;
    font-weight: 200;
    font-style: normal;
    padding-bottom: 20px;
}

.titulo span {
    font-weight: 400;
    font-size: 1.5em;
    color: #00b7ff;
}

.img img {
    max-width: 400px;
}

.btn {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.btn a {
    padding: 20px;
    font-size: 0.8em;
    font-weight: 600;
    width: 80px;
    text-decoration: none;
    text-transform: uppercase;
    border: solid 2px #00b7ff;
    border-radius: 10px;
    color: white;
    background: none;
    cursor: pointer;
}

.btn a:hover {
    color: #00b7ff;
    background-color: #292929;
}

@media (max-width: 768px) {
    .img {
        display: none;
    }
}