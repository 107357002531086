@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&display=swap');

.sobre {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 120px;
    background-color: #111111;
    font-family: 'Open Sans', sans-serif;
}

.img img {
    width: 500px;
}

.sobre-mim {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: justify;
    font-size: 1.2em;
    color: white;
    width: 500px;
}

.sobre-mim h2 {
    padding-bottom: 50px;
}

@media (max-width: 640px) {
    .sobre-mim {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        font-size: 1.2em;
        color: white;
        width: 500px;
    }
}