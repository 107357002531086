.footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: white;
    background-color: #111111;
}

.contatos {
    display: flex;
    flex-direction: row;
    gap: 30px;
    padding: 30px;
    align-items: center;
}

.contatos a {
    font-size: 2em;
    color: white;
}

.contatos a:hover{
    color: #00b7ff;
}

.creditos a {
    padding: 30px;
    text-decoration: none;
    color: white;
}

@media (max-width: 768px) {
    .footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

    }

    .creditos a {
        padding: 0px;
        text-decoration: none;
        color: white;
    }
}

@media (max-width: 490px) {
    .contatos h3 {
        display: none;
    }
}